import React from 'react';
import PropTypes from 'prop-types';

import * as S from './LinksGroup.styles';
import mailIcon from '../../assets/icons/mail-icon.svg';
import phoneIcon from '../../assets/icons/phone-icon.svg';

const LinksGroup = ({ isMobile }) => {
  return (
    <S.Links $isMobile={isMobile}>
      <a
        href="mailto:optymisja@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <S.LinkWrapper>
          <S.LinkIcon src={mailIcon} alt="mail-icon" />

          <S.LinkContent>optymisja@gmail.com</S.LinkContent>
        </S.LinkWrapper>
      </a>
      <a href="tel:+48-533-208-445">
        <S.LinkWrapper>
          <S.LinkIcon src={phoneIcon} alt="phone-icon" />
          <S.LinkContent>+48 533 208 445</S.LinkContent>
        </S.LinkWrapper>
      </a>
    </S.Links>
  );
};

export default LinksGroup;

LinksGroup.propTypes = {
  isMobile: PropTypes.bool,
};

LinksGroup.defaultProps = {
  isMobile: false,
};
