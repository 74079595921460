import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { theme } from '../../styles/theme';
import SectionWrapper from '../Common/SectionWrapper';
import * as S from './ContactForm.styles';
import LinksGroup from './LinksGroup';
import waveSmallIcon from '../../assets/icons/wave-small-orange.svg';
import SuccessMessage from './SuccessMessage';
import SubmitBtn from './SubmitBtn';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const ContactForm = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const onSubmit = async (data) => {
    const settings = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        ...data,
      }),
    };

    try {
      const response = await fetch('/', settings);

      if (!response.ok) {
        throw Error('Oops');
      }

      setIsSuccess(true);
    } catch (error) {
      console.log(error);
      setIsError(true);
    }
  };

  const handleReturnToForm = () => {
    reset();
    setIsSuccess(false);
    setIsError(false);
  };

  let content;

  if (isSuccess) {
    content = <SuccessMessage onReturnClick={handleReturnToForm} />;
  } else {
    content = (
      <>
        <S.ContentWrapper>
          <S.Title>{t('contact_form_title')}</S.Title>
          <S.Description>
            <S.Subtitle $isOrange>{t('contact_form_subtitle1')}</S.Subtitle>
            <S.Subtitle>{t('contact_form_subtitle2')}</S.Subtitle>
            <S.Subtitle>{t('contact_form_subtitle3')}</S.Subtitle>
          </S.Description>
          <LinksGroup />
        </S.ContentWrapper>
        <S.Form
          onSubmit={handleSubmit(onSubmit)}
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          {/* #netlify */}
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" {...register('bot-field')} />

          <S.WaveIconSmall src={waveSmallIcon} alt="wave-small" />
          <S.InputWrapper>
            <S.Input
              $isError={!!errors.name}
              placeholder={t('contact_form_label_firstName')}
              defaultValue=""
              {...register('name', {
                required: {
                  value: true,
                  message: t('form_error_required'),
                },
              })}
            />
            {errors.name && (
              <S.ErrorMessage>{errors.name.message}</S.ErrorMessage>
            )}
          </S.InputWrapper>
          <S.InputWrapper>
            <S.Input
              $isError={!!errors.surname}
              placeholder={t('contact_form_label_lastName')}
              defaultValue=""
              {...register('surname', {
                minLength: {
                  value: 5,
                  message: t('form_error_to_short'),
                },
              })}
            />
            {errors.surname && (
              <S.ErrorMessage>{errors.surname.message}</S.ErrorMessage>
            )}
          </S.InputWrapper>
          <S.InputWrapper>
            <S.Input
              $isError={!!errors.email}
              placeholder={t('contact_form_label_email')}
              defaultValue=""
              {...register('email', {
                required: {
                  value: true,
                  message: t('form_error_required'),
                },
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: t('form_error_invalid_email'),
                },
              })}
            />
            {errors.email && (
              <S.ErrorMessage>{errors.email.message}</S.ErrorMessage>
            )}
          </S.InputWrapper>
          <S.InputWrapper>
            <S.Input
              $isError={!!errors.phone}
              placeholder={t('contact_form_label_phone')}
              defaultValue=""
              {...register('phone', {
                pattern: {
                  value: /^[0-9\-\+]{9,15}$/,
                  message: t('form_error_invalid_phone'),
                },
              })}
            />
            {errors.phone && (
              <S.ErrorMessage>{errors.phone.message}</S.ErrorMessage>
            )}
          </S.InputWrapper>
          <S.MessageWrapper>
            <S.Message
              $isError={!!errors.message}
              placeholder={t('contact_form_label_message')}
              defaultValue=""
              {...register('message', {
                required: { value: true, message: t('form_error_required') },
                minLength: {
                  value: 30,
                  message: t('form_error_to_short'),
                },
              })}
            />
            {errors.message && (
              <S.ErrorMessage>{errors.message.message}</S.ErrorMessage>
            )}
          </S.MessageWrapper>
          <S.BtnWrapper>
            <SubmitBtn
              text={t('contact_form_submit_btn')}
              isLoading={isSubmitting}
            />
            {isError && (
              <S.ErrorMessage $isGeneral>
                {t('form_error_general')}
              </S.ErrorMessage>
            )}
          </S.BtnWrapper>
        </S.Form>
        <LinksGroup isMobile />
      </>
    );
  }

  return (
    <SectionWrapper backgroundColor={theme.color.backgroundSecondary}>
      <S.Wrapper>{content}</S.Wrapper>
    </SectionWrapper>
  );
};

export default ContactForm;
