import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

import * as S from '../Common/Header.styles';

const HeaderContact = () => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.HeaderText>{t('contact_header')}</S.HeaderText>
      </S.HeaderWrapper>
      <StaticImage
        src="../../assets/images/contact-banner.png"
        alt="group-training"
        placeholder="blurred"
      />
    </S.Wrapper>
  );
};

export default HeaderContact;
