import styled from 'styled-components';
import { query } from '../../styles/mediaQuery';

export const Links = styled.div`
  display: ${({ $isMobile }) => ($isMobile ? 'flex' : 'none')};
  flex-direction: column;
  gap: 2.4rem;
  justify-content: center;
  align-items: flex-start;

  a {
    text-decoration: none;
  }

  @media ${query.desktop} {
    display: ${({ $isMobile }) => ($isMobile ? 'none' : 'flex')};
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LinkIcon = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 2.4rem;
`;

export const LinkContent = styled.p`
  color: ${({ theme }) => theme.color.textSecondary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  margin: 0;
`;
