/* eslint-disable indent */
import styled from 'styled-components';
import { query } from '../../styles/mediaQuery';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60rem;
  margin: auto;

  @media ${query.desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3.8rem;
    max-width: 1100px;
    margin: auto;
  }
`;

export const ContentWrapper = styled.div`
  @media ${query.desktop} {
  }
`;

export const Title = styled.h2`
  text-transform: capitalize;
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.headingSmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0 0 1.6rem;

  @media ${query.desktop} {
    margin: 0;
    font-size: ${({ theme }) => theme.font.size.headingMedium};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
  }
`;

export const Description = styled.div`
  @media ${query.desktop} {
    margin: 2rem 0 4.8rem 0;
  }
`;

export const Subtitle = styled.p`
  color: ${({ theme, $isOrange }) =>
    $isOrange ? theme.color.primaryOrange : theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: 2.8rem;
  margin: 0;
  position: relative;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
  margin: 4rem 0;

  @media ${query.desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    margin: 6rem 0 0;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  padding: 1rem 1.5rem;
  border: ${({ theme, $isError }) =>
    $isError
      ? `0.15rem solid ${theme.color.error}`
      : `0.1rem solid ${theme.color.inputBorder}`};
  border-radius: 0.5rem;
  outline: none;

  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  width: 100%;
  background-color: #ffffff !important;

  ::placeholder {
    color: ${({ theme }) => theme.color.inputPlaceholder};
  }
`;

export const MessageWrapper = styled.div`
  position: relative;
  height: 25rem;

  @media ${query.desktop} {
    grid-column: 1/3;
    height: 15rem;
  }
`;

export const Message = styled.textarea`
  width: 100%;
  padding: 1rem 1.5rem;
  border: ${({ theme, $isError }) =>
    $isError
      ? `0.15rem solid ${theme.color.error}`
      : `0.1rem solid ${theme.color.inputBorder}`};
  border-radius: 0.5rem;
  outline: none;

  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.regular};

  resize: none;
  height: 100%;

  ::placeholder {
    color: ${({ theme }) => theme.color.inputPlaceholder};
  }
`;

export const ErrorMessage = styled.span`
  position: absolute;
  bottom: ${({ $isGeneral }) => ($isGeneral ? '-2.5rem' : '-1.7rem')};
  left: 0;
  width: 120%;
  color: ${({ theme }) => theme.color.error};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme, $isGeneral }) =>
    $isGeneral ? theme.font.size.navigation : theme.font.size.caption};
  font-weight: ${({ theme }) => theme.font.weight.regular};

  @media ${query.desktop} {
    font-size: ${({ theme, $isGeneral }) =>
      $isGeneral ? theme.font.size.paragraph : theme.font.size.caption};
  }
`;

export const BtnWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

export const WaveIconSmall = styled.img`
  display: none;

  @media ${query.desktop} {
    display: block;
    position: absolute;
    left: 0rem;
    top: -5rem;
  }
`;

export const SuccessMessage = styled.div`
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media ${query.desktop} {
    height: 26rem;
    gap: 1rem;
  }
`;

export const ReturnBtn = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: ${({ theme }) => theme.color.primaryOrange};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  margin-top: 4.8rem;

  @media ${query.desktop} {
    margin: 0;
  }
`;
