import React from 'react';
import PropTypes from 'prop-types';

import { PrimaryButton } from '../Common/Buttons/Buttons.styles';
import Loading from '../Common/Loading';

const SubmitBtn = ({ text, isLoading }) => {
  let content;

  if (isLoading) {
    content = <Loading />;
  } else {
    content = text;
  }

  return (
    <PrimaryButton disabled={isLoading} $widthDesktop="19rem">
      {content}
    </PrimaryButton>
  );
};

export default SubmitBtn;

SubmitBtn.propTypes = {
  text: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
