import React from 'react';

import * as S from './Loading.styles';

const Loading = () => {
  return (
    <S.Loading>
      <div />
      <div />
      <div />
      <div />
    </S.Loading>
  );
};

export default Loading;
