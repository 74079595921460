import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import * as S from './ContactForm.styles';

const SuccessMessage = ({ height, onReturnClick }) => {
  const { t } = useTranslation();

  return (
    <S.SuccessMessage $height={height}>
      <S.Title>{t('contact_form_succes_title')}</S.Title>
      <S.Subtitle>{t('contact_form_success_subtitle')}</S.Subtitle>
      <S.ReturnBtn onClick={onReturnClick}>
        {t('contact_form_success_action')}
      </S.ReturnBtn>
    </S.SuccessMessage>
  );
};

export default SuccessMessage;

SuccessMessage.propTypes = {
  height: PropTypes.number.isRequired,
  onReturnClick: PropTypes.func.isRequired,
};
